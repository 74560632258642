function getConstants() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const deg0 = (Math.PI / 180);
    const deg120 = (Math.PI / 180)* 120;
    return {
        order: {
            cost: ['quality', 'time'],
            quality: [ 'time', 'cost' ],
            time: [ 'cost', 'quality' ],
        },
        height,
        width,
        max: (height < width) ? height*0.8 : width*0.8,
        mincenter: 32,
        deg0cos: Math.cos(deg0),
        deg0sin: Math.sin(deg0),
        deg120cos: Math.cos(deg120),
        deg120sin: Math.sin(deg120),
        smallRadius: 20,
        bigRadius: 25,
        offset: 25,
    }
}

export default getConstants;
