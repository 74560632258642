import React, { useState } from 'react';
import getConstants from './helpers/mathconstants.helper';

const { smallRadius, bigRadius } = getConstants();

const Node = (props) => {
    const [r, setr] = useState(bigRadius);

    const setNewCoo = (x, y) => {
        if(props.setCoo) props.setCoo(x, y)
    }
    const move = (event) => {
        event.stopPropagation();
        if(event.type === "touchmove") {
            setNewCoo(event.touches[0].clientX, event.touches[0].clientY);
        } else {
            document.onmousemove = ((e) => {
                setNewCoo(e.pageX, e.pageY);
                document.onmouseup = ((e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                })
            })
        }
    };


    const moveOver = () => setr(smallRadius);
    const moveOut = () => setr(bigRadius);

    return (<circle
        cy={props.y}
        cx={props.x}
        r={r}
        fillOpacity="0"
        onMouseDown={move}
        onMouseEnter={moveOver}
        onMouseLeave={moveOut}
        onTouchEnd={moveOut}
        onTouchMove={move}
        stroke={props.stroke}
        strokeOpacity="0.7"
        strokeWidth='1'
    />
    );
};

export default Node;
