import React, { useState, useEffect } from 'react';

const Progress = (props) => {
    const [barwidth, setbarwidth] = useState(500);
    const [baroffset, setbaroffset] = useState(80);
    const progressbars = React.createRef();
    const setNewCoo = (x, y) => {
        if(props.moveDia) props.moveDia((x - baroffset +2)/ barwidth)
    }
    const move = (event) => {
        event.stopPropagation();

        if(event.type === "touchmove") {
            setNewCoo(event.touches[0].clientX, event.touches[0].clientY);
        } else {
            document.onmousemove = ((e) => {
                setNewCoo(e.pageX, e.pageY)
                document.onmouseup = ((e) => {
                    setNewCoo(e.pageX, e.pageY, )
                    document.onmousemove = null;
                    document.onmouseup = null;
                })
            });
        }
    };
    useEffect(() => {
        setbarwidth(progressbars.current.offsetWidth);
        setbaroffset(progressbars.current.getBoundingClientRect().left);
    }, [progressbars]);
    return (
        <div>
            <div className="labels">
                <label className="left">{props.labelleft}</label>
                <label className="right">{props.labelright}</label>
            </div>
            <div ref={progressbars} className='progress'>
                <div style={{width: props.percent +'%'}} className='bar'>
                    <div
                        className='point'
                        onMouseDown={move}
                        onTouchMove={move}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Progress;
