import React from 'react';
import Diagram from './Diagram.js';
import './style/App.scss';

function App() {
    document.body.addEventListener('touchmove', function(e) {
            e.preventDefault();
    });
  return (
    <div className="App">
        <Diagram/>
    </div>
  );
}

export default App;
