import getConstants from './mathconstants.helper';
let { mincenter } = getConstants();

export function calculateRemainingDia(remaining, dia) {
    let newDia = 0;
    if(remaining < 0) {
        newDia = dia - (remaining * (remaining/dia));
    } else {
        newDia = dia + (remaining * (remaining/dia));
    }
    if(newDia < mincenter)  newDia = mincenter;
    return newDia;
}
