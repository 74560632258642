import React from 'react';
const Target = (props) => {
    const { x, y,} = props;
    return (
        <g>
            <line
                x1={x - 10}
                x2={x + 10}
                y1={y}
                y2={y}
                stroke="#FFFFFF"
                strokeOpacity="0.6"
            />
            <line
                x1={x}
                x2={x}
                y1={y - 10}
                y2={y + 10}
                stroke="#FFFFFF"
                strokeOpacity="0.6"
            />
        </g>
    );
};

export default Target;
