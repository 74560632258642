import React from 'react';
import getConstants from './helpers/mathconstants.helper';

const { offset } = getConstants();

const Text = (props) => {
    const x = (isNaN(props.x))? 1 : props.x + offset;
    const y = (isNaN(props.y))? 1 : props.y - offset;
    return (<text x={x} y={y} fill="#E65100">{props.text} <tspan fill="#FFFFFF">%{props.percent}</tspan></text>);
};

export default Text;
