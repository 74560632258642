import React, { Component } from 'react';
import Circle from './Circle.js';
import Text from './Text.js';
import Progress from './Progress.js';
import Target from './Target.js';
import getConstants from './helpers/mathconstants.helper';
import { calculateRemainingDia } from './helpers/math.helper';

let { order, height, width, max, mincenter, deg120cos, deg120sin } = getConstants();

export default class Diagram extends Component {
    constructor(props)  {
        super(props);
        this.selector = React.createRef();
        this.state = {
            center_x: width/2,
            center_y: height/2,
            cost_x: 284,
            cost_y: 180 ,
            time_x: 382 ,
            time_y: 351 ,
            quality_x: 185,
            quality_y: 351,
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    updateDimensions(){
        const [ param1, param2 ] = order['cost'];
        const constants = getConstants();
        height = constants.height;
        width = constants.width;
        max = constants.max;
        const coo = this.movepoint(width/2, height/3);
        this.setState({ cost_x: coo.x, cost_y: coo.y, center_x: width/2,center_y: height/2});
        const newcoo = this.rotatecenter(coo.x, coo.y, this.state[`${param1}_x`], this.state[`${param1}_y`], max/3);
        const newcoo2 = this.rotatecenter(newcoo.x, newcoo.y, this.state[`${param2}_x`], this.state[`${param2}_y`], max/3);
        const newcoo3 = this.rotatecenter(newcoo2.x, newcoo2.y, this.state[`cost_x`], this.state[`cost_y`], max/3);
        this.setState({
            cost_x: newcoo3.x,
            cost_y: newcoo3.y,
            cost_p: 33.33,
            quality_x: newcoo.x,
            quality_y: newcoo.y,
            quality_p: 33.33,
            time_x: newcoo2.x,
            time_y:  newcoo2.y,
            time_p: 33.33,
        });
    }
    getDia = (x, y) => {
        const a = x - this.state.center_x;
        const b = y - this.state.center_y;
        return Math.sqrt( a*a + b*b  );
    }
    moveDia = (param) => (percentile) => {
        const x = this.state[`${param}_x`];
        const y = this.state[`${param}_y`];
        const cx = this.state.center_x;
        const cy = this.state.center_y;
        let diameter = this.getDia(this.state[`${param}_x`], this.state[`${param}_y`])
        let newdiameter = Math.abs(max*percentile);
        if(newdiameter < mincenter) newdiameter = mincenter;
        const move = newdiameter / diameter;
        const nx = (x - cx)* move + cx;
        const ny = (y - cy)* move + cy;
        this.setCoo(param)(nx,ny)
    }
    rotatecenter = (x, y, original_x, original_y, newdia = false) => {
        const cx = this.state.center_x;
        const cy = this.state.center_y;
        let diameter = this.getDia(original_x, original_y)
        let newdiameter = this.getDia(x, y)

        if(newdia) diameter = newdia;
        const move = diameter/newdiameter;
        const nx = ((deg120cos * (x - cx)) + (deg120sin * (y - cy)))* move + cx;
        const ny = ((deg120cos * (y - cy)) - (deg120sin * (x - cx)))* move + cy;
        return { x: nx, y: ny};
    }
    movepoint(x, y) {
        const cx = this.state.center_x;
        const cy = this.state.center_y;
        if(x === cx && y === cy) return { x: cx + 1, y: cy + 1 };
        const dia  = this.getDia(x,y);
        const move = (dia > max) ? max/dia : 1
        return {
            x: (x - cx) * move + cx,
            y: (y - cy) * move + cy
        };
    }
    getnewdia(x, y, param) {
        const [ param1, param2 ] = order[param];
        let actualcoo = this.movepoint(x, y)
        let dia1 = this.getDia(actualcoo.x, actualcoo.y);
        let dia2 = this.getDia( this.state[`${param1}_x`], this.state[`${param1}_y`]);
        let dia3 = this.getDia( this.state[`${param2}_x`], this.state[`${param2}_y`]);
        const restdia = (max - dia1 - dia2 - dia3)
        const ndia2 = calculateRemainingDia(restdia*(dia2/(dia2+dia3)), dia2);
        const ndia3 = calculateRemainingDia(restdia*(dia3/(dia2+dia3)), dia3);
        return { ndia2, ndia3, ndia1: dia1 };
    }
    setCoo = (param) => (x, y) => {
        const CooObject = {};
        const [ param1, param2 ] = order[param];
        const coo = this.movepoint(x, y);
        const { ndia3, ndia2, ndia1 } = this.getnewdia(coo.x, coo.y, param);
        const totaldia = ndia1 + ndia2 + ndia3;
        const newcoo = this.rotatecenter(coo.x, coo.y, this.state[`${param1}_x`], this.state[`${param1}_y`], ndia2);
        const newcoo2 = this.rotatecenter(newcoo.x, newcoo.y, this.state[`${param2}_x`], this.state[`${param2}_y`], ndia3);
        CooObject[`${param}_x`] = coo.x;
        CooObject[`${param}_y`] = coo.y;
        CooObject[`${param}_p`] = (ndia1/totaldia*100).toFixed(2);
        CooObject[`${param1}_x`] = newcoo.x;
        CooObject[`${param1}_y`] = newcoo.y;
        CooObject[`${param1}_p`] = (ndia2/totaldia*100).toFixed(2);
        CooObject[`${param1}_x`] = newcoo.x;
        CooObject[`${param2}_x`] = newcoo2.x;
        CooObject[`${param2}_y`] = newcoo2.y;
        CooObject[`${param2}_p`] = (ndia3/totaldia*100).toFixed(2);
        this.setState(CooObject);
    }
    render() {
        const { cost_x, cost_y, cost_p, time_x, time_y, time_p, quality_x, quality_y, quality_p, center_x, center_y,} = this.state;
        const points = `${cost_x},${cost_y} ${time_x},${time_y} ${quality_x},${quality_y}`;
        return (
            <div>
                <svg ref={this.selector} id="mysvg" height="100%" width="100%">
                    <polygon points={points} style={{fill: '#032059'}} />
                    <Text x={time_x} y={time_y} percent={time_p} text="Fast"/>
                    <Text x={quality_x} y={quality_y} percent={quality_p}  text="Good" />
                    <Text x={cost_x} y={cost_y} percent={cost_p} text="Cheap" />
                    <Circle x={time_x} y={time_y} stroke="#FFFFFF"  setCoo={this.setCoo('time')}/>
                    <Circle x={cost_x} y={cost_y}  stroke="#FFFFFF" setCoo={this.setCoo('cost')} />
                    <Circle x={quality_x} y={quality_y} stroke="#FFFFFF" setCoo={this.setCoo('quality')} />
                    <Target x={center_x} y={center_y} />
                </svg>
                <div className='progressbars'>
                    <Progress percent={cost_p} moveDia={this.moveDia('cost')} labelleft="Expensive" labelright="Cheap"/>
                    <Progress percent={quality_p} moveDia={this.moveDia('quality')} labelleft="Low Quality" labelright="High Quality"/>
                    <Progress percent={time_p} moveDia={this.moveDia('time')} labelleft="Slow" labelright="Fast"/>
                </div>
            </div>
        );
    }
}
